/**
 * 搜索头
 */
export const searchHeaderOptions = [
  {
    field: "driver_name",
    label: "司机姓名",
    type: "input",
    iw: 160,
    props: {
      placeholder: "请输入司机姓名"
    }
  },
  {
    field: "driver_mobile",
    label: "手机号码",
    type: "input",
    iw: 160,
    props: {
      placeholder: "请输入手机号码"
    }
  }
];
